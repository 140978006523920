.NewApptForm__section {
	padding: 20px;
}

body {
	background-color: #000000;
	color: #edf2f4;
	overflow-x: hidden;
}

.StepOne_form {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100vh;
	justify-content: space-evenly;
}

.landing-page {
	padding: 50px 10%;
}

.link-container {
	margin: 20px;
}

.link {
	text-decoration: none;
	color: #fff;
	background-color: #103378;
	padding: 10px 20px;
	border-radius: 20px;
}

.link:hover,
.link:active {
	background-color: #1745a1;
}

input[type="number"],
input[type="tel"] {
	border: none;
	border-radius: 5px;
	padding: 5px;
	font-size: 16px;
}
select {
	font-size: 16px;
	border: none;
	border-radius: 5px;
	padding: 8px;
	position: relative;
}

select > option {
	margin-top: 10px;
	padding: 10px;
}
.percent-buttons-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.percent-buttons {
	margin: 10px;
	padding: 15px 50px;
	font-size: 16px;
	border: none;
	border-radius: 5px;
}
.percent-buttons:hover,
.percent-active {
	background-color: #c2cbcf;
}
.team-inputs {
	display: flex;
	flex-direction: column;
}
.team-radio {
	padding: 10px;
	text-align: left;
}
.header-img {
	width: 100vw;
	overflow: hidden;
}
.img-container {
	overflow: hidden;
}
.NewApptForm__form {
	display: inline-block;
	background-color: #23395b;
	padding: 30px;
	margin: 50px auto;
	font-size: 18px;
	border-radius: 5px;
	text-align: center;
}

.NewApptForm__title {
	font-family: "Comfortaa", sans-serif;
	color: #edf2f4;
	margin: 5px;
	padding: 5px;
}

.NewApptForm__question {
	font-family: "Fjalla One", sans-serif;
	font-weight: 100;
	color: #edf2f4;
}

.NewApptForm__input {
	font-family: "Roboto", sans-serif;
	font-size: 16px;
	background-color: #edf2f4;
	padding: 3px 5px;
	border: none;

	border-radius: 5px;
	margin: 0 10px;
}

.NewApptForm__label {
	color: #edf2f4;
}

.NewApptForm__main {
	display: flex;
}

.NewApptForm__btn {
	font-family: "Roboto", sans-serif;
	font-size: 16px;
	background-color: #161925;
	color: #edf2f4;
	padding: 10px 15px;
	border: none;
	margin: 10px;
	border-radius: 10px;
	cursor: pointer;
}

.NewApptForm__btn:hover {
	background-color: #000000;
}

.NewApptForm__buttons {
	display: flex;
	font-size: 16px;
	justify-content: center;
}

.NewApptForm__text {
	font-family: "Roboto", sans-serif;
	font-size: 16px;
	display: block;
	margin: 10px auto;
	padding: 5px;
	border-radius: 5px;
}

.time-blocks {
	cursor: pointer;
	color: #edf2f4;
	border: 1px solid #edf2f4;
	border-radius: 3px;
	margin: 10px;
}

.time-list {
	cursor: pointer;
	text-align: center;
	margin: 10px;
}

.time-blocks:hover,
.selected {
	color: #161925;
	background-color: #8ea8c3;
}

.form-time {
	padding: 20px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.form-appt-type {
	font-family: "Roboto", sans-serif;
	font-size: 16px;
	margin: 0 10px;
	border-radius: 3px;
}

.back {
	display: inline;
}

.disabled {
	pointer-events: none;
	color: grey;
}
