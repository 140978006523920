.App {
	text-align: center;
}

.canvas-element {
	height: 100vh;
	width: 100vw;
	position: absolute;
	top: 0;
	left: 0;
	pointer-events: none;
}
